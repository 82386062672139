import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Projects" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://apps.apple.com/us/app/polyword/id1299707282"
        }}>{`Polyword`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://apps.apple.com/fr/app/sloth-news/id1217046910"
        }}>{`Sloth News`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://apps.apple.com/fr/app/sneaky-cats/id1185831439"
        }}>{`Sneaky Cat`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      